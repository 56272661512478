import React from 'react';

const ContentHeader = () => {
  return (
    <div className="content-header">
    </div>
  );
};

export default ContentHeader;
